.menuToggle {
    display: flex;
    flex-direction: column;
    position: relative;
    top: 0px;
    left: 34px;
    z-index: 999;
    -webkit-user-select: none;
    user-select: none;
  }
  
  .menuToggle input {
    display: flex;
    width: 40px;
    height: 32px;
    position: absolute;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
    padding: 10px;
    
    
  }
  
  .menuToggle span {
    display: flex;
    width: 29px;
    height: 2px;
    margin-bottom: 5px;
    position: relative;
    background: #fff;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 5px 0px;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
  }
  
  .menuToggle span:first-child {
    transform-origin: 0% 0%;
  }
  
  .menuToggle span:nth-last-child(2) {
    transform-origin: 0% 100%;
  }
  
  .menuToggle input:checked ~ span {
    opacity: 1;
    transform: rotate(45deg) translate(-3px, -1px);
    background: #36383f;
  }
  .menuToggle input:checked ~ span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }
  
  .menuToggle input:checked ~ span:nth-last-child(2) {
    transform: rotate(-45deg) translate(0, -1px);
  }
  
  .menu {
    position: absolute;
    width: 100vw;
    height: auto;
    box-shadow: 0 0 10px #85888c;
    margin: -200px 0 0 -90vw;
    padding: 50px;
    padding-top: 125px;
    background-color: #f5f6fa;
    -webkit-font-smoothing: antialiased;
    transform-origin: 0% 0%;
    transform: translate(-100%, 0);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  
  }
  
  .menu li {
    padding: 10px 0;
    text-align: start;
    transition-delay: 2s;
  
  }
  
  .menuToggle input:checked ~ ul {
    transform: none;
  }
  